import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView, trackUserRole, trackSessionDuration } from "./analytics";

const AnalyticsTracker = ({ userRole }) => {
    const location = useLocation();

    useEffect(() => {
        if (!userRole || userRole === "KPO") {
            trackPageView(location.pathname);

            if (userRole) {
              trackUserRole(userRole);
  
              // Track session start time
              const startTime = Date.now();
  
              return () => {
                trackSessionDuration(userRole, startTime);
              };
            }
        }
    }, [location, userRole]);

    return null;
};

export default AnalyticsTracker;
