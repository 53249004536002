const KujaShopSuccess = () => {
  return (
    <div className="flex flex-col items-center justify-end w-screen h-screen overflow-hidden md:justify-start ">
      <div className="flex flex-col items-center mt-[168px] bg-white px-6 md:px-[75px] pt-10 h-auto md:h-[305px] max-w-[679px] rounded-2xl mx-4 text-center mb-40">
        <img
          src="/assets/svg/Check.svg"
          alt="Success"
          className="w-[40px] h-[40px] md:w-[60px] md:h-[60px]"
        />
        <p className="pt-4 text-2xl font-semibold">Thank you!</p>

        <p className="pt-5 text-base text-[#505253]">
          Thank you for completing the form. A customer service representative
          will be in touch with you shortly.
        </p>

        <div className="flex flex-col items-center justify-center pt-4 md:flex-row pb-[34px] text-smaller-text">
          <p>Download the KujaShop app here:&nbsp;</p>
          <a
            href="https://play.google.com/store/apps/details?id=app.shopdc.com"
            className="text-[#1155E5] underline underline-offset-2"
          >
            Link to KujaShop App
          </a>
        </div>
      </div>
    </div>
  );
};

export default KujaShopSuccess;
