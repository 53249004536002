import ReactGA from 'react-ga';

const { REACT_APP_GOOGLE_TRACKING_ID } = process.env;

const TRACKING_ID = REACT_APP_GOOGLE_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

// Function to track page views
export const trackPageView = (page) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

// Function to track user roles
export const trackUserRole = (role) => {
    ReactGA.event({
        category: "User",
        action: "Role Assigned",
        label: role,
    });
};

// Function to track session duration
export const trackSessionDuration = (role, startTime) => {
    const duration = Date.now() - startTime;
    ReactGA.event({
        category: "User Engagement",
        action: "Session Duration",
        label: role,
        value: duration, // in milliseconds
    });
};
