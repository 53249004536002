import KujaShopSuccess from "./success";
import { useState } from "react";
import axiosInstance from "../../utils/axios";
import urls from "../../utils/urls";

const { userNet } = urls;

const InputField = ({ label, name, type, placeholder, value, onChange }) => (
  <div className="w-full md:w-[360px] lg:w-[460px] xl:w-[512px] md:pr-4">
    <label htmlFor={name} className="block mb-2 text-sm font-medium">
      {label}
    </label>
    <input
      required
      id={name}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="w-full h-14 p-4 mb-6 text-base bg-white border-[0.7px] rounded border-[#787A7D] focus:outline-none focus:ring-2 focus:ring-gold"
    />
  </div>
);

const KujaShopForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    outletName: "",
    outletAddress: "",
    ownerFullName: "",
    ownerPhoneNumber: "",
    hearAboutUs: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const {
      outletName,
      outletAddress,
      ownerFullName,
      ownerPhoneNumber,
    } = formData;
    return (
      outletName &&
      outletAddress &&
      ownerFullName &&
      ownerPhoneNumber
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await axiosInstance.post(`${userNet}user/survey/erp`, formData);
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };


  return (
    <>
      {formSubmitted ? (
        <KujaShopSuccess />
      ) : (
        <div className="flex flex-col items-center w-screen h-screen pt-[88px] md:pt-[168px] overflow-scroll">
          <img
            src="/assets/svg/KjShop.svg"
            alt="Kuja Shop Logo"
            className="w-[40px] h-[40px] md:w-[60px] md:h-[60px]"
          />
          <p className="pt-6 text-[16px] font-semibold md:text-2xl">
            Hello there, welcome to Kuja Shop
          </p>
          <p className="px-4 pt-5 font-normal text-center text-smaller-text text-[#505253]">
            Complete the form below to join Kuja Shop and enjoy the experience
          </p>

          <form
            className="w-full px-4 pt-6 md:pt-14 md:w-auto"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col md:flex-row">
              <InputField
                label="Outlet name"
                name="outletName"
                placeholder="Enter name of outlet"
                value={formData.outletName}
                onChange={handleChange}
              />
              <InputField
                label="Outlet address"
                name="outletAddress"
                placeholder="Address must include city, state, nearest bus stop"
                value={formData.outletAddress}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col md:flex-row">
              <InputField
                label="Owner’s full name"
                name="ownerFullName"
                placeholder="Outlet owner full name"
                value={formData.ownerName}
                onChange={handleChange}
              />
              <InputField
                label="Owner’s telephone number"
                name="ownerPhoneNumber"
                placeholder="Enter phone number"
                value={formData.ownerPhoneNumber}
                onChange={handleChange}
                type={"number"}
              />
            </div>

            <div className="flex flex-col md:flex-row">
              <p className="text-sm font-medium">
                I will like to hear more about&nbsp;
              </p>

              <div className="flex">
                <p className="pr-2 text-sm font-medium md:pr-4">KUJAShop?</p>
                <div className="flex md:-mt-0.5">
                  <label
                    className={`flex items-center pr-2 ${
                      formData.hearAboutUs === true
                        ? "text-black"
                        : "text-[##787A7D]"
                    }`}
                  >
                    <input
                      type="radio"
                      name="hearAboutUs"
                      value={true}
                      checked={formData.hearAboutUs === true}
                      onChange={() =>
                        handleChange({
                          target: { name: "hearAboutUs", value: true },
                        })
                      }
                      className="mr-2"
                    />
                    Yes
                  </label>
                  <label
                    className={`flex items-center pr-2 ${
                      formData.hearAboutUs === false
                        ? "text-black"
                        : "text-[##787A7D]"
                    }`}
                  >
                    <input
                      type="radio"
                      name="hearAboutUs"
                      value={false}
                      checked={formData.hearAboutUs === false}
                      onChange={() =>
                        handleChange({
                          target: { name: "hearAboutUs", value: false },
                        })
                      }
                      className="mr-2"
                    />
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className="flex justify-center my-8">
              <button
                type="submit"
                className={`bg-gold rounded-full text-sm font-medium py-3 w-full md:w-[360px] lg:w-[460px] xl:w-[512px] h-[48px] ${
                  !validateForm() ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!validateForm()}
              >
                Submit Form
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default KujaShopForm;
